import { ViewportScroller } from '@angular/common';
import { inject, NgModule, NgZone } from '@angular/core';
import { CanActivateFn, NavigationEnd, Router, RouterModule, Routes, Scroll } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { hideApexRoot } from './app.component';
import DoubleStarComponent from './components/double-star/double-star.component';
import { CompanyConsentReminderResolver } from './features/consent/reminder/company-consent-reminder.resolver';
import { HelpURLCollectionByLanguageResolver } from './features/help-url/resolvers/help-url-collection-by-language.resolver';
import { GetProfileResolver } from './services/user/profile.resolve';
import { UserService } from './services/user/user.service';
import { hostMatchesApp } from './utils/functions';

// @todo This needs to be moved/removed once Portal is ready for Client consumption.
export const ClientNoAccessGuard: CanActivateFn = (): Observable<boolean> => {
  const userService = inject(UserService);

  return userService.profile$.pipe(map((profile) => profile?.role !== 1));
};

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: '',
    resolve: {
      profile: GetProfileResolver,
      helpUrls: HelpURLCollectionByLanguageResolver,
      companyConsentReminder: CompanyConsentReminderResolver,
    },
    children: [
      {
        path: 'field',
        loadChildren: () => import('./features/field/field.module').then((mod) => mod.FieldModule),
      },
      {
        path: 'takeover-recipient',
        loadChildren: () =>
          import('./features/takeover-recipient/takeover-recipient.module').then((mod) => mod.TakeoverRecipientModule),
      },
      {
        path: 'addon',
        loadChildren: () => import('./features/addon/addon.module').then((mod) => mod.AddonModule),
      },
      {
        path: 'reporting',
        loadChildren: () => import('./features/reporting/reporting.module').then((mod) => mod.ReportingModule),
      },
      {
        path: 'project',
        loadChildren: () => import('./features/project/project.module').then((mod) => mod.ProjectModule),
      },
      {
        path: 'minside',
        loadChildren: () => import('./features/client-page/client-page.module').then((mod) => mod.ClientPageModule),
      },
      {
        path: 'client-page-info',
        loadChildren: () =>
          import('./features/client-page-info/client-page-info.module').then((mod) => mod.ClientPageInfoModule),
      },
      {
        path: 'default-text',
        loadChildren: () => import('./features/default-text/default-text.module').then((mod) => mod.DefaultTextModule),
      },
      {
        path: 'signing',
        loadChildren: () => import('./features/signing/signing.module').then((mod) => mod.SigningModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./features/http-error/http-error.module').then((mod) => mod.HttpErrorModule),
      },
      {
        path: 'object',
        loadChildren: () => import('./features/object/object.module').then((mod) => mod.ObjectModule),
      },
      {
        path: 'takeover',
        loadChildren: () =>
          import('../../../apex/src/app/features/takeover/takeover.module').then((mod) => mod.TakeoverModule),
      },
      {
        path: 'checklist-template',
        loadChildren: () =>
          import('./features/checklist-template/checklist-template.module').then((mod) => mod.ChecklistTemplateModule),
      },
      {
        path: 'checklist',
        loadChildren: () => import('./features/checklist/checklist.module').then((mod) => mod.ChecklistModule),
      },
      {
        path: 'checklist-group-template',
        loadChildren: () =>
          import('./features/checklist-group-template/checklist-group-template.module').then(
            (mod) => mod.ChecklistGroupTemplateModule,
          ),
      },
      {
        path: 'checklist-group',
        loadChildren: () =>
          import('./features/checklist-group/checklist-group.module').then((mod) => mod.ChecklistGroupModule),
      },
      {
        path: 'checklists',
        loadChildren: () => import('./features/checklist-start/start.module').then((mod) => mod.ChecklistStartModule),
      },
      {
        path: 'apex-it',
        loadChildren: () => import('./features/apex-it/apex-it.module').then((mod) => mod.APEXITModule),
      },
      {
        path: 'case',
        loadChildren: () => import('./features/case/case.module').then((mod) => mod.CaseModule),
      },
      {
        path: 'portal',
        loadChildren: () => import('./features/portal/portal.module').then((mod) => mod.ApexPortalModule),
      },
      {
        path: 'inspection',
        loadChildren: () => import('./features/inspection/inspection.module').then((mod) => mod.InspectionModule),
      },
      {
        path: 'bookmark',
        loadChildren: () => import('./features/bookmark/bookmark.module').then((mod) => mod.BookmarkModule),
      },
      {
        path: 'text',
        loadChildren: () => import('./features/text/text.module').then((mod) => mod.TextModule),
      },
      {
        path: 'folder',
        loadChildren: () => import('./features/folder/folder.module').then((mod) => mod.FolderModule),
      },
      {
        path: 'customer',
        loadChildren: () => import('./features/customer/customer.module').then((mod) => mod.CustomerModule),
      },
      {
        path: 'crm-user',
        loadChildren: () => import('./features/crm-user/crm-user.module').then((mod) => mod.CRMUserModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./features/user/user.module').then((mod) => mod.UserModule),
      },
      {
        path: 'company',
        loadChildren: () => import('./features/company/company.module').then((mod) => mod.CompanyModule),
      },
      {
        path: 'apartment',
        loadChildren: () => import('./features/apartment/apartment.module').then((mod) => mod.ApartmentModule),
      },
      {
        path: 'nobb',
        loadChildren: () => import('./features/nobb/nobb.module').then((m) => m.NOBBModule),
      },
      {
        path: 'request',
        loadChildren: () =>
          import('./features/request/list-table/request-list-table.module').then((m) => m.RequestListTableModule),
      },
      {
        path: 'file',
        loadChildren: () => import('./features/file/file.module').then((m) => m.FileModule),
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminStartModule),
      },
      {
        path: 'mom-builder',
        loadChildren: () => import('./features/project-mom/mom.module').then((m) => m.MOMModule),
      },
      {
        path: 'import',
        loadChildren: () => import('./features/import/import.module').then((m) => m.ImportModule),
      },
      {
        path: 'construction-site',
        loadChildren: () =>
          import('./features/construction-site/construction-site.module').then((m) => m.ConstructionSiteModule),
      },
      {
        path: 'mass-mailing',
        loadChildren: () => import('./features/mass-mailing/mass-mailing.module').then((m) => m.MassMailingModule),
      },
      {
        path: 'client',
        loadChildren: () => import('./features/client/client.module').then((m) => m.ClientModule),
      },
      {
        path: 'mom-viewer',
        loadChildren: () => import('./features/apartment-mom/apartment-mom.module').then((m) => m.ApartmentMOMModule),
      },
      {
        path: 'consent',
        loadChildren: () => import('./features/consent/consent.module').then((m) => m.ConsentModule),
      },
      {
        path: 'unwanted-incident',
        loadChildren: () =>
          import('./features/unwanted-incident/unwanted-incident.module').then((m) => m.UnwantedIncidentModule),
      },
      {
        path: 'article',
        loadChildren: () => import('./features/article/article.module').then((m) => m.ArticleModule),
      },
      {
        path: 'help-url',
        loadChildren: () => import('./features/help-url/help-url.module').then((m) => m.default),
      },
      {
        path: '**',
        component: DoubleStarComponent,
        children: [],
      },
    ],
  },
];

const routesMapped = routes[1].children.map((r) => r.path);

routesMapped.push('auth'); // To ensure auth gets non hidden.

if (hostMatchesApp) {
  routes[1].canActivate = [ClientNoAccessGuard];
}

const APEXANGULARJSEVENT = 'APEXAngularJSEvent';
const APEXANGULARNAVIGATEBYURL = 'APEXAngularNavigateByUrl';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    private ngZone: NgZone,
    private viewportScroller: ViewportScroller,
  ) {
    window[APEXANGULARNAVIGATEBYURL] = (u: string): void => {
      this.ngZone.run(() => {
        void this.router.navigateByUrl(u, {
          replaceUrl: true,
          skipLocationChange: true,
        });
      });
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = new URL(event.url, 'https://exmaple.com');
        const startOfUrl = url.pathname
          .split('/')
          .filter((s) => s)
          .shift();

        if (routesMapped.indexOf(startOfUrl) !== -1) {
          hideApexRoot.next(false);

          ['frame', 'overlay-card'].forEach((d) => document.getElementById(d)?.remove());
        } else {
          if (!hostMatchesApp) {
            hideApexRoot.next(true);

            // We do not care about these routes.
            // Let AngularJS know we did something.
            if (window[APEXANGULARJSEVENT]) {
              window[APEXANGULARJSEVENT]('NavigationEnd');
            }
          }
        }
      }

      if (event instanceof Scroll) {
        if (window.history?.state?.skipScroll) {
          return;
        }

        setTimeout(() => {
          if (event.position) {
            this.viewportScroller.scrollToPosition(event.position);
          } else if (event.anchor) {
            if (!hostMatchesApp) {
              this.viewportScroller.scrollToAnchor(event.anchor);
            }

            /**
             * Seems APP has trouble using Angular anchors.
             * The browser knows that to do tho.
             * @todo Once AngularJS is gone we need to clean all this up.
             */
            const elem = document.getElementById(event.anchor);

            if (elem) {
              elem.scrollIntoView();
            }
          } else {
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }, 25);
      }
    });
  }
}
